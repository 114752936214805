<template>
  <b-overlay :show="loading" class="row">
    <div class="col-12 col-lg-7 c g">
      <div class="card">
        <div class="card-header">
          <h4>
            <i class="fa fa-cogs"></i>
            {{ e("settings") }}
          </h4>
        </div>
        <div class="card-body">
          <!-- switch -->
          <div class="demo-inline-spacing">
            <b-form-checkbox
              v-model="date_type"
              class="custom-control-success"
              name="check-button"
              value="hijri"
              switch
              inline
            >
              <strong> {{ e("use-hijri") }}</strong>
            </b-form-checkbox>
          </div>
          <!-- switch -->
          <div class="demo-inline-spacing">
            <b-form-checkbox
              class="custom-control-success"
              name="check-button"
              v-model="branches"
              value="true"
              switch
              inline
            >
              <strong> {{ e("use-branches") }}</strong>
            </b-form-checkbox>
          </div>
          <!-- switch -->
          <div class="demo-inline-spacing">
            <b-form-checkbox
              class="custom-control-success"
              name="check-button"
              v-model="accept_all_app_devices"
              :value="true"
              switch
              inline
            >
              <strong> {{ e("accept_all_app_devices") }}</strong>
            </b-form-checkbox>
          </div>
          <div class="g border row" v-if="branches" style="margin-top: 6px">
            <div class="col-12 col-lg-4 g">
              <button
                class="btn btn-relief-primary btn-lg btn-block"
                @click="addBranche()"
              >
                <i class="fa fa-plus"></i>
                {{ e("add-branche") }}
              </button>
            </div>
            <div
              class="col-12 col-lg-4 g"
              v-for="branche in branches_list"
              :key="branche.id"
            >
              <div class="g border">
                <button
                  @click="deleteBranche(branche.id)"
                  class="btn btn-sm btn-outline-danger fa fa-times"
                ></button>
                <input
                  type="text"
                  class="form-control"
                  v-model="branche.title"
                />
              </div>
            </div>
          </div>
          <div class="col-12 col-lg-6 g">
            <br />
            <div class="form-group">
              <h5 for="">
                {{ e("timezone-for-app") }}
              </h5>
              <select class="form-control form-control-lg" v-model="time_zone">
                <template v-for="tz in timezones">
                  <option :value="tz.en" :key="tz.en">{{ e(tz.en) }}</option>
                </template>
              </select>
            </div>
          </div>
        </div>
        <div class="card-footer text-center">
          <button class="btn btn-lg btn-relief-success" @click="saveNow()">
            <i class="fa fa-save"></i>
            {{ e("save") }}
          </button>
        </div>
      </div>
    </div>
  </b-overlay>
</template>

<script>
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

import { BLink, BOverlay, BFormCheckbox } from "bootstrap-vue";
export default {
  components: {
    BLink,
    BOverlay,
    BFormCheckbox,
  },
  data() {
    return {
      loading: false,
      date_type: null,
      timezones: timezones,
      branches: null,
      time_zone: null,
      user: JSON.parse(localStorage.getItem("user")),
      branches_list: [],
      accept_all_app_devices: false,
    };
  },
  created() {
    this.date_type = this.user.date_type;
    this.branches = this.user.branches;
    this.branches_list = this.user.branches_list;
    this.time_zone = this.user.time_zone;
    this.accept_all_app_devices = this.user.accept_all_app_devices;
  },
  methods: {
    e(d) {
      return e(d);
    },
    deleteBranche(branche) {
      if (confirm(e("confirm-delete-branche"))) {
        var arr = [];
        this.branches_list.forEach((element) => {
          if (element.id != branche) {
            arr.push(element);
          }
        });
        this.branches_list = arr;
      }
    },
    addBranche() {
      this.branches_list.push({
        title: "",
        id: Math.random().toString().split(".")[1],
      });
    },
    saveNow() {
      var _g = this;
      _g.loading = true;
      $.post(api + "/general/settings", {
        jwt: this.user.jwt,
        date_type: this.date_type,
        branches: this.branches,
        branches_list: this.branches_list,
        time_zone: this.time_zone,
        accept_all_app_devices: this.accept_all_app_devices,
      })
        .then(function (response) {
          response = JSON.parse(response);
          if (response.status == 100) {
            _g.$toast({
              component: ToastificationContent,
              props: {
                title: e("settings-saved"),
                icon: "CheckIcon",
                variant: "success",
              },
            });
            setTimeout(() => {
              _g.$toast({
                component: ToastificationContent,
                props: {
                  title: e("loading"),
                  icon: "ClockIcon",
                  variant: "warning",
                },
              });
              $.post(api + "/auth/check", {
                jwt: _g.user.jwt,
              }).then(function (data) {
                data = JSON.parse(data);
                localStorage.setItem("user", JSON.stringify(data.response));
                location.reload();
              });
            }, 100);
          } else {
            _g.$toast({
              component: ToastificationContent,
              props: {
                title: e("error"),
                icon: "TimesIcon",
                variant: "danger",
              },
            });
            _g.loading = false;
          }
        })
        .catch(function () {
          _g.$toast({
            component: ToastificationContent,
            props: {
              title: e("error"),
              icon: "TimesIcon",
              variant: "danger",
            },
          });
          _g.loading = false;
        });
    },
  },
};
</script>